import React from 'react';
import { motion } from 'framer-motion';
import Navigation from "./Navigation";
import Footer from "./Footer";
import './OurTeam.css';
const teamMembers = [
  {  name: 'Kshitij Singh Bhati', role: 'Captain', image: '/images/captain.jpg' },
  { name: 'Jaswant Singh Ranawat', role: 'Vice Captain', image: '/images/vice-captain.jpg' },
  { name: 'Manas Kumar', role: 'Core Member', image: '/images/member1.jpg' },
  { name: 'Shivraj Singh Shekhawat', role: 'Core Member', image: '/images/member2.jpg' },
  { name: 'Mohit Singh Visht', role: 'Core Member', image: '/images/member3.jpg' },
  { name: 'Shahid Khan', role: 'Core Member', image: '/images/member4.jpg' },
  { name: 'Pradeep Choudhary', role: 'Core Member', image: '/images/member5.jpg' },
  { name: 'Vivek Choudhary', role: 'Core Member', image: '/images/member6.jpg' },
  { name: 'Koushal Joshi', role: 'Core Member', image: '/images/member7.jpg' },
  { name: 'Lakshay Nirban', role: 'Core Member', image: '/images/member8.jpg' },
  { name: 'Shivam Sharma', role: 'Core Member', image: '/images/member9.jpg' },
  { name:'Nandini Sethiya', role: 'Core Member', image: '/images/member10.jpg'},
];
const OurTeam = () => {
  return (
    <div className="page-container bg-cover bg-center bg-teal-900 relative" style={{backgroundImage: "url('/images/gaming-background.jpg')"}}>
      <div className="gradient-overlay"></div>
      <div className="content-wrap relative z-10 flex flex-col min-h-screen">
        <Navigation />
        <main className="flex-grow flex flex-col items-center justify-center px-4 lg:px-20 py-10 lg:py-20">
          <h1 className="responsive-title font-bold mb-8 text-white text-center">
            Our Team
          </h1>
          
          {/* Top Members Section */}
          <div className="top-members">
            <motion.div
              className="team-member-container"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <div className={`team-member captain`}>
                <img src={teamMembers[0].image} alt={teamMembers[0].name} />
              </div>
              <h2 className="member-name">{teamMembers[0].name}</h2>
              <p className="member-role">{teamMembers[0].role}</p>
            </motion.div>
            <motion.div
              className="team-member-container"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <div className={`team-member vice-captain`}>
                <img src={teamMembers[1].image} alt={teamMembers[1].name} />
              </div>
              <h2 className="member-name">{teamMembers[1].name}</h2>
              <p className="member-role">{teamMembers[1].role}</p>
            </motion.div>
          </div>

          {/* Core Members Section */}
          <div className="core-members">
            {teamMembers.slice(2).map((member, index) => (
              <motion.div
                key={index}
                className="core-member-container"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <div className={`team-member`}>
                  <img src={member.image} alt={member.name} />
                </div>
                <h2 className="member-name">{member.name}</h2>
                <p className="member-role">{member.role}</p>
              </motion.div>
            ))}
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};
export default OurTeam;