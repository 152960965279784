import React from 'react';
import { motion } from 'framer-motion';
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { Button } from "./ui/button";
import Navigation from "./Navigation";
import Footer from "./Footer";
import { FaInstagram, FaEnvelope } from 'react-icons/fa';
import './shared.css';

const ContactUs = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Form submitted');
  };

  return (
    <div className="page-container bg-cover bg-center bg-teal-900 relative" style={{backgroundImage: "url('/images/gaming-background.jpg')"}}>
      <div className="gradient-overlay"></div>
      <div className="content-wrap relative z-10 flex flex-col h-screen">
        <Navigation />
        <main className="flex-grow flex flex-col items-center justify-center px-4 lg:px-20 py-6 overflow-y-auto">
          <motion.h1
            className="responsive-title font-bold mb-4 text-white text-center"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            Contact <span className="text-teal-400">Us</span>
          </motion.h1>
          
          <motion.div
            className="bg-black/70 p-4 rounded-lg max-w-2xl w-full mb-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 1 }}
          >
            <form onSubmit={handleSubmit} className="space-y-3">
              <Input
                type="text"
                placeholder="Your Name"
                className="w-full bg-gray-800 text-white border-gray-700"
                required
              />
              <Input
                type="email"
                placeholder="Your Email"
                className="w-full bg-gray-800 text-white border-gray-700"
                required
              />
              <Input
                type="text"
                placeholder="Subject"
                className="w-full bg-gray-800 text-white border-gray-700"
                required
              />
              <Textarea
                placeholder="Your Message"
                className="w-full bg-gray-800 text-white border-gray-700"
                rows={4}
                required
              />
              <Button 
                type="submit" 
                className="w-full join-tournament-btn"
              >
                Send Message
              </Button>
            </form>
          </motion.div>

          <motion.div
            className="flex justify-center space-x-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 1 }}
          >
            <a href="https://www.instagram.com/esports.club_piet" target="_blank" rel="noopener noreferrer" className="text-white hover:text-teal-400 transition-colors duration-300">
              <FaInstagram size={32} />
            </a>
            <a href="mailto:esports@poornima.org" className="text-white hover:text-teal-400 transition-colors duration-300">
              <FaEnvelope size={32} />
            </a>
          </motion.div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default ContactUs;
