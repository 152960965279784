import axios from 'axios';

const api = axios.create({
  baseURL: 'https://perfect-pixels-backend.onrender.com',
});

export const API_URL = 'https://perfect-pixels-backend.onrender.com';

export const register = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/api/users/register`, userData);
    return response;
  } catch (error) {
    throw error;
  }
};

export const googleAuth = async (tokenId) => {
  try {
    const response = await api.post('/auth/google', { tokenId });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Add a test function to check CORS
export const testCORS = async () => {
  try {
    const response = await api.get('/test-cors');
    console.log('CORS test response:', response.data);
    return response;
  } catch (error) {
    console.error('CORS test error:', error.response?.data || error.message);
    throw error;
  }
};

export default api;