import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Button } from "./ui/button";
import Navigation from "./Navigation";
import Footer from "./Footer";
import './JoinClub.css'; // We'll create this CSS file for custom animations
import './shared.css';
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"

const JoinClub = () => {
  const navigate = useNavigate();

  const handleJoinClub = () => {
    navigate('/register');
  };

  const handleImageError = (e) => {
    console.error(`Error loading image: ${e.target.src}`);
    e.target.src = '/images/placeholder.jpg'; // Fallback image
  };

  return (
    <div className="page-container bg-cover bg-center bg-teal-900 relative" style={{backgroundImage: "url('/images/gaming-background.jpg')"}}>
      <div className="gradient-overlay"></div>
      <div className="content-wrap relative z-10 flex flex-col min-h-screen">
        <Navigation />
        <main className="flex flex-col lg:flex-row items-center justify-between px-4 lg:px-20">
          <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
            <motion.h1
              className="responsive-title font-bold mb-8 text-white text-center lg:text-left"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              Welcome to the <span className="text-teal-400">E-Sports</span><br />Multiverse
            </motion.h1>
            <motion.div 
              className="flex justify-center lg:justify-start"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1, duration: 1 }}
            >
              <Button 
                onClick={handleJoinClub}
                className="glow-on-hover"
              >
                Join The Multiverse
              </Button>
            </motion.div>
          </div>

          <motion.div
            className="w-full lg:w-1/2 grid grid-cols-2 gap-4 lg:gap-6"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.5, duration: 1 }}
          >
            <div className="game-logo-container">
              <img src="/images/csgo-logo.jpg" alt="CS:GO" className="game-logo" onError={handleImageError} />
            </div>
            <div className="game-logo-container">
              <img src="/images/pubg-logo.jpg" alt="PUBG" className="game-logo" onError={handleImageError} />
            </div>
            <div className="game-logo-container">
              <img src="/images/lol-logo.jpg" alt="Fortnite" className="game-logo" onError={handleImageError} />
            </div>
            <div className="game-logo-container">
              <img src="/images/valorant-logo.jpg" alt="Valorant" className="game-logo" onError={handleImageError} />
            </div>
          </motion.div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default JoinClub;
