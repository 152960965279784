import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Button } from "../components/ui/button";
import Navigation from "./Navigation";
import Footer from "./Footer";
import './shared.css';

const RegisterOptions = () => {
  return (
    <div className="page-container bg-cover bg-center bg-teal-900" style={{backgroundImage: "url('/images/gaming-background.jpg')"}}>
      <div className="gradient-overlay"></div>
      <div className="content-wrap">
        <Navigation />
        <main className="flex items-center justify-center px-4 lg:px-20 py-10 lg:py-20">
          <motion.div 
            className="options-container bg-black/70 p-6 rounded-lg"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <h2 className="text-2xl lg:text-3xl mb-8 text-white font-bold text-center">
              Choose Your <span className="text-teal-400">Registration</span> Option
            </h2>
            <motion.div 
              className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 1 }}
            >
              <Link to="/register/vice-captain" className="w-full">
                <Button 
                  variant="default" 
                  size="lg" 
                  className="w-full glow-on-hover"
                >
                  Apply for Vice Captain
                </Button>
              </Link>
              <Link to="/register/core-member" className="w-full">
                <Button 
                  variant="default" 
                  size="lg" 
                  className="w-full glow-on-hover"
                >
                  Register as Core Member
                </Button>
              </Link>
              <Link to="/register/member" className="w-full">
                <Button 
                  variant="default" 
                  size="lg" 
                  className="w-full glow-on-hover"
                >
                  Register as Member
                </Button>
              </Link>
            </motion.div>
          </motion.div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default RegisterOptions;
