import React from 'react';
import { motion } from 'framer-motion';
import Navigation from "./Navigation";
import Footer from "./Footer";
import './shared.css';

const AboutUs = () => {
  return (
    <div className="page-container bg-cover bg-center bg-teal-900 relative" style={{backgroundImage: "url('/images/gaming-background.jpg')"}}>
      <div className="gradient-overlay"></div>
      <div className="content-wrap relative z-10 flex flex-col min-h-screen">
        <Navigation />
        <main className="flex flex-col items-center justify-center px-4 lg:px-20 py-10 lg:py-20">
          <motion.h1
            className="responsive-title font-bold mb-8 text-white text-center"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            About <span className="text-teal-400">E-Sports</span> Club
          </motion.h1>
          <motion.div
            className="bg-black/70 p-6 rounded-lg max-w-3xl"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 1 }}
          >
            <p className="text-white mb-4">
              The E-Sports Club is a vibrant community of gamers passionate about competitive gaming. Our mission is to foster talent, promote sportsmanship, and create exciting opportunities for gamers to showcase their skills.
            </p>
            <p className="text-white mb-4">
              Founded in 2023, we've quickly grown to become one of the most active gaming communities in the region. We organize regular tournaments, training sessions, and social events to bring gamers together.
            </p>
            <p className="text-white">
              Whether you're a casual gamer or aspiring pro, the E-Sports Club welcomes all who share our passion for gaming. Join us and be part of the next generation of e-sports champions!
            </p>
          </motion.div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default AboutUs;
