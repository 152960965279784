import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { register, googleAuth, testCORS } from '../config/api';
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Textarea } from "../components/ui/textarea";
import { Checkbox } from "../components/ui/checkbox";
import Navigation from "./Navigation";
import Footer from "./Footer";
import { motion } from 'framer-motion';
import './shared.css';

const RegisterForm = () => {
  const { role } = useParams(); // Get the role from the URL params

  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    regNumber: '',
    email: '',
    whyJoin: '',
    gamingExperience: '',
    favoriteGames: '',
    skills: {
      teamManagement: false,
      eventOrganizing: false,
      contentCreation: false,
      streaming: false,
    },
  });
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? {
        ...prevState[name],
        [value]: checked
      } : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      console.log('Sending registration data:', { ...formData, role });
      const response = await register({ ...formData, role });
      console.log('Registration response:', response.data);
      alert('Registered Successfully!');
    } catch (err) {
      console.error('Registration error:', err);
      let errorMessage = 'Registration Failed: ';
      if (err.response) {
        console.error('Error response:', err.response.data);
        if (err.response.data.details && err.response.data.details.includes('duplicate key error')) {
          errorMessage += 'An account with this email already exists.';
        } else {
          errorMessage += err.response.data.error || err.response.data.msg || err.response.statusText;
        }
      } else if (err.request) {
        errorMessage += 'No response received from server';
      } else {
        errorMessage += err.message;
      }
      setError(errorMessage);
    }
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log('Google login success, tokenResponse:', tokenResponse);
      try {
        // Get the ID token
        const idToken = await getIdToken(tokenResponse.access_token);
        const response = await googleAuth({ token: idToken, role });
        console.log('Backend response:', response.data);
        // Handle successful login here (e.g., redirect or update state)
      } catch (error) {
        console.error('Error during Google authentication:', error);
        setError('Google authentication failed. Please try again.');
      }
    },
    onError: (errorResponse) => {
      console.error('Google login error:', errorResponse);
      setError('Google Sign-In failed. Please try again.');
    },
    flow: 'implicit',
  });

  // Function to get ID token from access token
  const getIdToken = async (accessToken) => {
    const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (!response.ok) {
      throw new Error('Failed to fetch user info');
    }
    const data = await response.json();
    return data.sub; // This is the user's Google ID
  };

  const handleCORSTest = async () => {
    try {
      const result = await testCORS();
      console.log('CORS test result:', result);
      alert('CORS test successful: ' + JSON.stringify(result.data));
    } catch (err) {
      console.error('CORS test failed:', err);
      alert('CORS test failed: ' + err.message);
    }
  };
  
  const inputClassName = "bg-white/30 text-white placeholder-gray-300 border border-white/50 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-lg font-semibold";

  const renderCommonFields = () => (
    <>
      <Input
        name="name"
        placeholder="Full Name"
        value={formData.name}
        onChange={handleChange}
        className={`w-full ${inputClassName}`}
        required
      />
      <Input
        name="phoneNumber"
        placeholder="Phone Number"
        value={formData.phoneNumber}
        onChange={handleChange}
        className={`w-full ${inputClassName}`}
        required
      />
      <Input
        name="regNumber"
        placeholder="Registration Number"
        value={formData.regNumber}
        onChange={handleChange}
        className={`w-full ${inputClassName}`}
        required
      />
      <Input
        name="email"
        type="email"
        placeholder="Email"
        value={formData.email}
        onChange={handleChange}
        className={`w-full ${inputClassName}`}
        required
      />
    </>
  );

  const renderViceCaptainFields = () => (
    <>
      {renderCommonFields()}
      <div className="space-y-2">
        <Textarea
          name="whyJoin"
          placeholder="Why do you want to be the Vice Captain of the E-Sports Club?"
          value={formData.whyJoin}
          onChange={handleChange}
          className={`w-full ${inputClassName}`}
          required
          rows={3}
        />
        <Textarea
          name="gamingExperience"
          placeholder="Describe your gaming experience and achievements"
          value={formData.gamingExperience}
          onChange={handleChange}
          className={`w-full ${inputClassName}`}
          required
          rows={3}
        />
      </div>
    </>
  );

  const renderCoreMemberFields = () => (
    <>
      {renderCommonFields()}
      <div className="space-y-2">
        <Textarea
          name="whyJoin"
          placeholder="Why do you want to be a core member of the E-Sports Club?"
          value={formData.whyJoin}
          onChange={handleChange}
          className={`w-full ${inputClassName}`}
          required
          rows={3}
        />
        <Textarea
          name="gamingExperience"
          placeholder="Describe your gaming experience and achievements"
          value={formData.gamingExperience}
          onChange={handleChange}
          className={`w-full ${inputClassName}`}
          required
          rows={3}
        />
        <Input
          name="favoriteGames"
          placeholder="Your favorite games (comma-separated)"
          value={formData.favoriteGames}
          onChange={handleChange}
          className={`w-full ${inputClassName}`}
          required
        />
      </div>
      <div className="space-y-2">
        <p className="text-white text-sm">Skills:</p>
        <div className="grid grid-cols-2 gap-4">
          <div className="checkbox-wrapper">
            <input
              type="checkbox"
              id="teamManagement"
              checked={formData.skills.teamManagement}
              onChange={(e) => handleChange({
                target: {
                  name: 'skills',
                  value: 'teamManagement',
                  type: 'checkbox',
                  checked: e.target.checked
                }
              })}
            />
            <label htmlFor="teamManagement">Team Management</label>
          </div>
          <div className="checkbox-wrapper">
            <input
              type="checkbox"
              id="eventOrganizing"
              checked={formData.skills.eventOrganizing}
              onChange={(e) => handleChange({
                target: {
                  name: 'skills',
                  value: 'eventOrganizing',
                  type: 'checkbox',
                  checked: e.target.checked
                }
              })}
            />
            <label htmlFor="eventOrganizing">Event Organizing</label>
          </div>
          <div className="checkbox-wrapper">
            <input
              type="checkbox"
              id="contentCreation"
              checked={formData.skills.contentCreation}
              onChange={(e) => handleChange({
                target: {
                  name: 'skills',
                  value: 'contentCreation',
                  type: 'checkbox',
                  checked: e.target.checked
                }
              })}
            />
            <label htmlFor="contentCreation">Content Creation</label>
          </div>
          <div className="checkbox-wrapper">
            <input
              type="checkbox"
              id="streaming"
              checked={formData.skills.streaming}
              onChange={(e) => handleChange({
                target: {
                  name: 'skills',
                  value: 'streaming',
                  type: 'checkbox',
                  checked: e.target.checked
                }
              })}
            />
            <label htmlFor="streaming">Streaming</label>
          </div>
        </div>
      </div>
    </>
  );

  const renderMemberFields = () => (
    <>
      {renderCommonFields()}
      <div className="space-y-2">
         <Textarea
          name="whyJoin"
          placeholder="Why do you want to join the E-Sports Club?"
          value={formData.whyJoin}
          onChange={handleChange}
          className={`w-full ${inputClassName}`}
          required
          rows={3}
        /> 
        <Input
          name="favoriteGames"
          placeholder="Your favorite games (comma-separated)"
          value={formData.favoriteGames}
          onChange={handleChange}
          className={`w-full ${inputClassName}`}
          required
        />
      </div>
      <div className="mt-4 flex justify-center">
        <button
          onClick={() => handleGoogleLogin()}
          type="button"
          className="flex items-center justify-center px-4 py-2 border border-gray-300 text-gray-700 bg-white rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <img
            className="w-6 h-6 mr-2"
            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
            alt="Google logo"
          />
          Sign up with Google
        </button>
      </div>
    </>
  );

  const getFormTitle = () => {
    switch (role) {
      case 'vice-captain':
        return (
          <>
            Apply For <span className="text-teal-400">Vice Captain</span>
          </>
        );
      case 'core-member':
        return (
          <>
            Register as <span className="text-teal-400">Core Member</span>
          </>
        );
      case 'member':
        return (
          <>
            Register as <span className="text-teal-400">Member</span>
          </>
        );
      default:
        return `Register as ${role}`;
    }
  };

  return (
    <div className="page-container bg-cover bg-center bg-teal-900" style={{backgroundImage: "url('/images/gaming-background.jpg')"}}>
      <div className="gradient-overlay"></div>
      <div className="content-wrap">
        <Navigation />
        <main className="flex items-center justify-center p-4">
          <motion.div 
            className="form-container bg-black/70 p-4 lg:p-6 rounded-lg"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <h2 className="text-2xl lg:text-3xl mb-4 text-white font-bold text-center">
              {getFormTitle()}
            </h2>
            {error && <p className="text-red-500 mb-4 text-sm">{error}</p>}
            
            <form onSubmit={handleSubmit} className="space-y-4">
              {role === 'vice-captain' && renderViceCaptainFields()}
              {role === 'core-member' && renderCoreMemberFields()}
              {role === 'member' && renderMemberFields()}
              
              <Button 
                type="submit" 
                className="w-full glow-on-hover"
              >
                Register
              </Button>
            </form>
          </motion.div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default RegisterForm;
