import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <footer className="bg-black/50 text-white py-4 mt-auto">
      <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center px-4">
        <p className="text-center sm:text-left mb-2 sm:mb-0">
          Developed by{' '}
          <a 
            href="https://kshitijsinghbhati.in" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-teal-400 hover:text-teal-300 transition-colors duration-300"
          >
            Kshitij Singh
          </a>
        </p>
        <div className="flex space-x-4">
          <a 
            href="https://www.instagram.com/_ksiij" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-2xl hover:text-teal-400 transition-colors duration-300"
            aria-label="Instagram"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a 
            href="https://www.linkedin.com/in/kshitijsinghbhati" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-2xl hover:text-teal-400 transition-colors duration-300"
            aria-label="LinkedIn"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
